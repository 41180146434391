<template>
  <a-layout>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>{{ siderData.title }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-layout-content class="content">
      <a-page-header
        class="demo-page-header"
        style=" padding: 0;"
        title="Leaves"
      >
      </a-page-header>
      <Daypickers />
    </a-layout-content>

    <a-layout-footer class="footer">&copy;2021 Springup Inc.</a-layout-footer>
  </a-layout>
</template>

<script>
import {
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  getCurrentInstance,
} from "vue";
import { useRouter } from "vue-router";
import Daypickers from "./Daypickers.vue";

export default {
  components: {
    Daypickers,
  },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const v = reactive({
      siderData: {
        title: router.currentRoute.value.name,
        list: [],
      },

      leaves: [],

      siderStatus: false,

      loading: false,

      shownameModal: false,
      newname: "",

      showpwdModal: false,
      oldpwd: "",
      newpwd1: "",
      newpwd2: "",
    });

    async function getleaves() {
      const res = await proxy.$axios.get(`/api/v1/leaves`);
      v.shownameModal = false;
    }

    async function changepwd() {
      proxy.$msg.success("change password success");
      v.showpwdModal = false;
    }

    return {
      ...toRefs(v),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
.footer {
  text-align: center;
}
.table {
  margin-top: 15px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
