<template>
  <div>
    <a-row justify="space-between" align="middle" class="my-20">
      <a-col>
        <a-button @click="togglemonth(0)">
          <LeftOutlined />
        </a-button>
      </a-col>
      <a-col>
        <a-typography-title :level="3" class="text-center" style="magin-bottom: 0">
          {{ $filters.formatTime(currentTime, "YYYY-MM") }}
        </a-typography-title>
      </a-col>
      <a-col>
        <a-button @click="togglemonth(1)">
          <RightOutlined />
        </a-button>
      </a-col>
    </a-row>

    <div class="mt-30">
      <a-row type="flex" justify="center">
        <a-col :xl="8">
          <div class="d-flex">
            <div class="my-10 text-center" :span="3" :push="3" style="width: 14%" v-for="(item, index) in week"
              :key="index">
              <a-typography-text class="text-center" style="font-size: 20px" strong type="secondary">
                {{ item }}
              </a-typography-text>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col :span="8" class="p-relative">
          <a-row type="flex" justify="center" align="middle"
            style="min-height: 300px; height: 100%; width: 100%; top: 0; background: rgba(255, 255, 255, 0.5); z-index: 99;"
            class="p-absolute" v-show="loading">
            <a-spin />
          </a-row>
          <div class="d-flex">
            <div class="py-10" style="width: 14%" v-for="(item, index) in daylist" :key="index">
              <div v-if="!item"></div>
              <div v-else class="day" :class="{ 'bg-e0': item, 'cursor-pointer': item.dayPart && item.dayPart != 'OFF' }">
                <div @click="openModal(item, 'AM')" :class="{
                  'bg-success':
                    item.dayPart == 'FULL' || item.dayPart == 'AM',
                  'bg-danger':
                    item.FULL || item.AM,

                }"></div>
                <div @click="openModal(item, 'PM')" :class="{
                  'bg-success':
                    item.dayPart == 'FULL' || item.dayPart == 'PM',
                  'bg-danger':
                    item.FULL || item.PM,
                }"></div>
                <span style=" user-select: none;pointer-events: none;"> {{ item.day }} </span>
                <span class="approved" v-if="item.ful?.approved">APPROVED</span>
                <span class="approved" v-else-if="item.AM?.approved">APPROVED</span>
                <span class="approved" v-else-if="item.PM?.approved">APPROVED</span>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>

    <a-modal v-model:visible="showModal" title="Leave" :maskClosable="false" :keyboard="false" centered width="900px">
      <a-row type="flex" justify="space-between" style="margin-bottom: 20px;">
        <h3>Submitted leave requests</h3>
        <a-button @click="openNewModal" type="primary" v-if="canCreateNew">Create new</a-button>
      </a-row>
      <a-table :dataSource="currentday.leaves" :columns="columns" :loading="loading" :rowKey="'id'" :pagination="{
        hideOnSinglePage: true,
      }" style="min-height: 300px;">
        <template #user="{ text }">
          {{ text.name }}
        </template>
        <template #leaveType="{ text }">
          {{ text }}
        </template>
        <template #approved="{ text }">
          {{ text == null ? "Unapproved" : text ? "Approved" : "Disapproved" }}
        </template>
        <template #tool="{ record }">
          <a-space>
            <a-button size="small" v-if="!record.approved" @click="handleTobeEdit(record)">update</a-button>
            <a-popconfirm title="Are you sure?" okText="sure" @confirm="deleteoffday(record)">
              <template #icon>
                <QuestionOutlined style="color: red" />
              </template>
              <a-button type="primary" danger :loading="loading" size="small">Delete</a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>

      <template #footer>
        <a-button :loading="loading" @click="showModal = false">Cancle</a-button>

      </template>
    </a-modal>


    <a-modal v-model:visible="newModal" title="Create Leave" :maskClosable="false" :keyboard="false" centered
      width="800px">
      <div class="">
        <a-row class="mb-10">
          <a-col :span="6">Date</a-col>
          <a-col>{{
            $filters.formatTime(currentday.date, "YYYY-MM-DD")
          }}</a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6">Dary Part</a-col>
          <a-col>
            <a-space align="center">
              <div class="day bg-e0">
                <div :class="{ 'bg-danger': leave.dayPart == 'FULL' || leave.dayPart == 'AM', }"></div>
                <div :class="{ 'bg-danger': leave.dayPart == 'FULL' || leave.dayPart == 'PM', }"></div>
                <span style=" user-select: none;pointer-events: none;"> {{ currentday.day }} </span>
                <div class="handle">
                  <div @click="changedayPart('AM')"></div>
                  <div @click="changedayPart('PM')"></div>
                </div>
              </div>
              <span v-if="leave.dayPart == 'AM'">上午</span>
              <span v-if="leave.dayPart == 'PM'">下午</span>
              <span v-if="leave.dayPart == 'FULL'">全天</span>
              (点击可选择半天)
            </a-space>
          </a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6">Leave Type</a-col>
          <a-col>
            <a-select ref="select" v-model:value="leave.leaveType" style="width: 150px">
              <a-select-option v-for="(item, index) in leave_types" :key="index" :value="item.type">
                {{ item.name }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6"></a-col>
          <a-col :span="18">
            {{ leave_types.find(e => e.type == leave.leaveType)?.description }}

          </a-col>
        </a-row>
        <a-row class="mb-10" v-if="leave.leaveType == 'ANNUAL'">
          <a-col :span="6">Annual Leaves</a-col>
          <a-col :span="18">
            <a-row>
              <a-col :span="4"> 共有: <a-typography-text strong>{{ annualLeaves.total }} 天</a-typography-text> </a-col>
              <a-col :span="4"> 已用: <a-typography-text strong>{{ annualLeaves.used }} 天</a-typography-text> </a-col>
              <a-col :span="4"> 剩余: <a-typography-text strong>{{ annualLeaves.total - annualLeaves.used }}
                  天</a-typography-text></a-col>
              <a-col :span="6"> 受雇时间: <a-typography-text strong>{{ $filters.formatTimeByDay(annualLeaves.employmentDays)
              }}</a-typography-text></a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="">
          <a-col :span="6">Leave Reason</a-col>
          <a-col :span="18">
            <a-textarea v-model:value="leave.leaveReason" :auto-size="{ minRows: 5 }" showCount :maxlength="255"
              placeholder="Reason" allow-clear />
          </a-col>
        </a-row>
      </div>
      <template #footer>
        <a-button key="submit" type="primary" :loading="loading" @click="saveSubmit"
          :disabled="leave.leaveReason.length < 6 || leave.dayPart == 'OFF'">
          Submit
        </a-button>
        <a-button @click="newModal = false">Cancle</a-button>
      </template>
    </a-modal>

    <a-modal v-model:visible="updateModal" title="Update Leave" :maskClosable="false" :keyboard="false" centered
      width="800px">
      <div class="">
        <a-row class="mb-10">
          <a-col :span="6">Date</a-col>
          <a-col>{{
            $filters.formatTime(tobeEdit.date, "YYYY-MM-DD")
          }}</a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6">Dary Part</a-col>
          <a-col>
            <a-space align="center">
              <div class="day bg-e0">
                <div :class="{ 'bg-danger': tobeEdit.dayPart == 'FULL' || tobeEdit.dayPart == 'AM', }"></div>
                <div :class="{ 'bg-danger': tobeEdit.dayPart == 'FULL' || tobeEdit.dayPart == 'PM', }"></div>
                <span style=" user-select: none;pointer-events: none;"> {{ currentday.day }} </span>
                <div class="handle">
                  <div @click="changedayPart('AM')"></div>
                  <div @click="changedayPart('PM')"></div>
                </div>
              </div>
              <span v-if="tobeEdit.dayPart == 'AM'">上午</span>
              <span v-if="tobeEdit.dayPart == 'PM'">下午</span>
              <span v-if="tobeEdit.dayPart == 'FULL'">全天</span>
            </a-space>
          </a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6">tobeEdit Type</a-col>
          <a-col>
            <a-select ref="select" v-model:value="tobeEdit.leaveType" style="width: 150px">
              <a-select-option v-for="(item, index) in leave_types" :key="index" :value="item.type">
                {{ item.name }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6"></a-col>
          <a-col :span="18">
            {{ leave_types.find(e => e.type == leave.leaveType)?.description }}

          </a-col>
        </a-row>
        <a-row class="mb-10" v-if="tobeEdit.leaveType == 'ANNUAL'">
          <a-col :span="6">Annual Leaves</a-col>
          <a-col :span="18">
            <a-row>
              <a-col :span="4"> 共有: <a-typography-text strong>{{ annualLeaves.total }} 天</a-typography-text> </a-col>
              <a-col :span="4"> 已用: <a-typography-text strong>{{ annualLeaves.used }} 天</a-typography-text> </a-col>
              <a-col :span="4"> 剩余: <a-typography-text strong>{{ annualLeaves.total - annualLeaves.used }}
                  天</a-typography-text></a-col>
              <a-col :span="6"> 受雇时间: <a-typography-text strong>{{ $filters.formatTimeByDay(annualLeaves.employmentDays)
              }}</a-typography-text></a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="">
          <a-col :span="6">Leave Reason</a-col>
          <a-col :span="18">
            <a-textarea v-model:value="tobeEdit.leaveReason" :auto-size="{ minRows: 5 }" showCount :maxlength="255"
              placeholder="Reason" allow-clear />
          </a-col>
        </a-row>
      </div>
      <template #footer>
        <a-button key="submit" type="primary" :loading="loading" @click="updateleave"
          :disabled="tobeEdit.leaveReason?.length < 6">
          Update
        </a-button>

        <a-button :loading="loading" @click="updateModal = false">Cancle</a-button>

      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import dayjs from "dayjs";

import {
  LeftOutlined,
  RightOutlined,
  QuestionOutlined,
} from "@ant-design/icons-vue";
const week = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const fullday = [1, 3, 5, 7, 8, 10, 12];


const columns = [
  {
    title: "Date",
    dataIndex: "date",
    ellipsis: true,
  },
  {
    title: "Leave Type",
    dataIndex: "leaveType",
    ellipsis: true,
    slots: {
      customRender: "leaveType",
    },
  },
  {
    title: "Day part",
    dataIndex: "dayPart",
  },
  {
    title: "Leave Reason",
    dataIndex: "leaveReason",
    ellipsis: false,
    slots: {
      customRender: "reason",
    },
  },
  {
    title: "Approval",
    dataIndex: "approved",
    ellipsis: true,
    slots: {
      customRender: "approved",
    },
  },
  {
    title: "...",
    dataIndex: "height",
    slots: {
      customRender: "tool",
    },
  },
];

export default {
  components: {
    LeftOutlined,
    RightOutlined,
    QuestionOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const v = reactive({
      week,
      daylist: [],
      leaves: [],
      workdays: [],
      currentTime: "",
      loading: false,
      showModal: false,
      leave_types: [],

      currentday: {},
      leave: {
        date: 20211021,
        dayPart: "FULL",
        leaveType: null,
        leaveReason: "",
      },
      annualLeaves: {
        total: 0, used: 0
      },
      newModal: false,
      tobeEdit: {},
      updateModal: false
    });

    watch(
      () => v.currentTime,
      () => {
        getleaves("changetime");
      }
    );

    v.currentTime = new Date().getTime();

    async function getleavetypes() {
      const res = await proxy.$axios.get("/api/v1/leave_types");
      if (res.data) {
        v.leave_types = res.data;
      } else {
        proxy.$msg.warning(
          "Please contact the administrator to add leave types"
        );
      }
    }
    getleavetypes();

    const userInfo = computed(() => store.state.userInfo);

    async function getleaves(time) {
      v.loading = true;
      const res = await proxy.$axios.get(`/api/v1/leaves`, {
        month: proxy.$filters.formatTime(v.currentTime, "YYYYMM"),
      });
      v.loading = false;
      if (res.data) {
        v.leaves = res.data;
        if (time) {
          await getworkdays();
          return;
        }
        if (v.workdays.length >= 1) {
          getdaylist(new Date().getTime(v.workdays[0].date));
        } else {
          getworkdays();
        }
      }
    }

    async function getworkdays() {
      try {
        v.loading = true;
        const res = await proxy.$axios.get(`/api/v1/workdays/users/${userInfo.value.id}`, {
          month: proxy.$filters.formatTime(v.currentTime, "YYYYMM"),
        });
        v.loading = false;
        if (res.data) {
          v.workdays = res.data;
          getdaylist(v.currentTime);
        }
      } catch (err) {
        v.loading = false;
        if (err.response && err.response.status == 400) {
          v.workdays = [];
          v.daylist = [];
          proxy.$notify.error({
            message: "Error", description: "You cannot query data more than two months before system startup.",
          });
        }
      }
    }


    function getdaylist(time) {
      let list = [];
      let oneday =
        time - (proxy.$filters.getDayofMonth(time) - 1) * (86400 * 1000);

      let offset = proxy.$filters.getDayofWeek(oneday);

      if (offset == 0) offset = 7;

      for (let i = 1; i < offset; i++) {
        list.push(null);
      }
      let month = proxy.$filters.getMonthofYear(time);

      let length = 30;
      if (month == 1) {
        length = proxy.$filters.isLeapYear(time) ? 29 : 28;
      } else if (fullday.some((e) => e == month + 1)) {
        length = 31;
      }

      for (let i = 1; i <= length; i++) {
        let date = proxy.$filters.formatTime(
          oneday + 86400000 * (i - 1),
          "YYYYMMDD"
        );
        let target = v.workdays.find((item) => item.date == date);
        let target2 = v.leaves.filter((item) => item.date == date);
        let AM = v.leaves.find((item) => item.date == date && item.dayPart == 'AM')
        let PM = v.leaves.find((item) => item.date == date && item.dayPart == 'PM')
        let FULL = v.leaves.find((item) => item.date == date && item.dayPart == 'FULL')
        list.push({
          day: i,
          date,
          dayPart: target ? target.dayPart : "OFF",
          leaves: target2 || [],
          AM, PM, FULL,
        });
      }
      v.daylist = list;
    }
    function togglemonth(type) {
      if (type) {
        v.currentTime = dayjs(v.currentTime).add(1, 'month').startOf('month').valueOf()
      } else {
        v.currentTime = dayjs(v.currentTime).subtract(1, 'month').startOf('month').valueOf()
      }
    }

    async function saveSubmit() {
      // if (UpdateOrSubmit.value == 'Update') {
      //   updateleave();
      //   return;
      // }
      let { date, dayPart, leaveType, leaveReason } = v.leave;
      if (dayPart == 'FULL') {
        if (v.currentday.PM || v.currentday.AM) {
          proxy.$msg.error("和已有的请假冲突请删除或更新已有的请假");
          return
        }
      }
      if (dayPart == 'AM' || dayPart == 'PM') {
        if (v.currentday.FULL) {
          proxy.$msg.error("和已有的请假冲突请删除或更新已有的请假");
          return
        }
      }

      try {
        v.loading = true;
        const res = await proxy.$axios.post(`/api/v1/leaves`, {
          date: Number(date),
          dayPart,
          leaveType: leaveType,
          leaveReason,
        });
        if (res.status == 200) {
          proxy.$msg.success("save success");
          v.newModal = false;
          v.leave.leaveReason = ''
          await getleaves(true);
          v.currentday[dayPart] = v.leave
          v.currentday = v.daylist.find(e => e && e.date == v.currentday.date)
          if (leaveType == 'ANNUAL') {
            getAnnualLeaves()
          }
        }
      } catch (err) {
        console.log(err);
        if (err.response) {
          proxy.$msg.error(err.response.data);
        } else {
          proxy.$msg.error(err)
        }
      } finally {
        v.loading = false;
      }
    }

    async function updateleave() {
      let { date, dayPart, leaveType, leaveReason, id } = v.tobeEdit;
      try {

        const res = await proxy.$axios.put(`/api/v1/leaves/${id}`, { date: Number(date), dayPart, leaveType, leaveReason });
        if (res.status == 200) {
          proxy.$msg.success("update success");
          v.showModal = false;
          getleaves(true);
          if (leaveType == 'ANNUAL') {
            getAnnualLeaves()
          }
        }
      } catch (error) {
        proxy.$msg.error(error.response.data);
      }
    }

    async function deleteoffday(item) {
      v.loading = true;
      try {
        const res = await proxy.$axios.delete(`/api/v1/leaves/${item.id}`);
        if (res.status == 200) {
          proxy.$msg.success("delete success");
          v.currentday.leaves = v.currentday.leaves.filter(e => e.id != item.id)
          v.currentday[item.dayPart] = null
          await getleaves();
          if (item.leaveType == 'ANNUAL') {
            getAnnualLeaves()
          }
        }
      } catch (error) {
        console.error(error);
      } finally { v.loading = false; }
    }

    function changedayPart(part) {
      if (v.leave.dayPart == "FULL") {
        v.leave.dayPart = part == "AM" ? "PM" : "AM";
      } else if (v.leave.dayPart == "AM") {
        v.leave.dayPart = part == "AM" ? "OFF" : "FULL";
      } else if (v.leave.dayPart == "PM") {
        v.leave.dayPart = part == "AM" ? "FULL" : "OFF";
      } else if (v.leave.dayPart == "OFF") {
        v.leave.dayPart = part;
      }
    }

    function openModal(item, dayPart) {
      if (item.dayPart == "OFF") return;

      // let leave = item[dayPart]
      // v.leave.dayPart = leave?.dayPart || "FULL";
      // v.leave.leaveReason = leave ? leave.leaveReason : "";
      // v.leave.leaveType = leave ? leave.leaveType : null;
      // v.leave.id = leave ? leave.id : null;
      // v.leave.approved = leave ? leave.approved : null;
      v.leave.date = item.date;
      v.currentday = item;
      v.showModal = true;
    }

    function openNewModal(item, dayPart) {

      // let leave = item[dayPart]
      // v.leave.dayPart = leave?.dayPart || "FULL";
      // v.leave.leaveReason = leave ? leave.leaveReason : "";
      // v.leave.leaveType = leave ? leave.leaveType : null;
      // v.leave.id = leave ? leave.id : null;
      // v.leave.approved = leave ? leave.approved : null;
      // v.leave.date = item.date;
      v.newModal = true;

    }

    const UpdateOrSubmit = computed(() => {
      if (v.leave.dayPart == 'PM' && v.currentday.PM) return 'Update'
      if (v.leave.dayPart == 'AM' && v.currentday.AM) return 'Update'
      if (v.leave.dayPart == 'FULL') {
        if (v.currentday.FULL) return 'Update';
        // if (v.currentday.AM && !v.currentday.PM) { return 'Update' }
        // if (!v.currentday.AM && v.currentday.PM) { return 'Update' }
      }
      return 'Submit'
    })

    const canCreateNew = computed(() => {
      if (v.currentday.FULL) return false
      if (v.currentday.AM && v.currentday.PM) return false
      return true
    })

    getAnnualLeaves()

    async function getAnnualLeaves() {
      try {
        const res = await proxy.$axios.get(`/api/v1/annual_leaves`);
        if (res.data) {
          v.annualLeaves = res.data
        }
      } catch (error) {
        console.error(error);
      }
    }

    function handleTobeEdit(item) {
      v.tobeEdit = JSON.parse(JSON.stringify(item));
      v.updateModal = true
    }

    return {
      ...toRefs(v),
      deleteoffday,
      togglemonth,
      saveSubmit,
      changedayPart,
      openModal,
      getAnnualLeaves,
      UpdateOrSubmit,
      columns,
      openNewModal,
      handleTobeEdit,
      updateleave,
      canCreateNew,
    };
  },
};
</script>

<style lang="scss" scoped>
.day {
  width: 50px;
  height: 50px;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: #000;
  position: relative;

  // overflow: hidden;
  div {
    height: 50%;
  }

  div:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  div:nth-child(2) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .handle {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

    div {
      height: 50%;
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .approved {
    font-size: 12px;
    line-height: 14px;
    color: #999999;
    transform: rotate(-45deg) translate(-20%, -135%);
    border: 1px solid #999999;
    border-radius: 5px;
    padding: 3px;
    user-select: none;
    pointer-events: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
